export default function ContarProductos(registros) {
  // Definir un objeto para almacenar el conteo de productos
  const conteoProductos = {};

  // Recorrer cada registro
  registros.forEach(registro => {
      // Obtener el nombre del producto del registro
      const producto = registro.Producto;

      // Si el producto ya está en el conteo, aumentar su conteo en 1
      // Si no, inicializar su conteo en 1
      if (conteoProductos[producto]) {
          conteoProductos[producto]++;
      } else {
          conteoProductos[producto] = 1;
      }
  });

  // Convertir el objeto de conteo a un array de objetos con el formato requerido
  const resultado = Object.keys(conteoProductos).map((producto, index) => ({
      id: index,
      value: conteoProductos[producto],
      label: producto
  }));

  return resultado;
}
