export default function contarRegistrosPorAsesor(registros) {
    // Definir un objeto para almacenar el conteo de registros por asesor
    const conteoRegistrosPorAsesor = {};

    // Recorrer cada registro
    registros.forEach(registro => {
        // Obtener el nombre completo del asesor del registro
        const nombreCompleto = registro.Nombre_de_Broker;

        // Obtener el primer nombre del nombre completo
        const primerNombre = nombreCompleto.split(' ')[0];

        // Si el asesor ya está en el conteo, aumentar su conteo en 1
        // Si no, inicializar su conteo en 1
        if (conteoRegistrosPorAsesor[registro.Broker_ID]) {
            conteoRegistrosPorAsesor[registro.Broker_ID]++;
        } else {
            conteoRegistrosPorAsesor[registro.Broker_ID] = 1;
        }
    });

    // Convertir el objeto de conteo a un array de objetos con los datos de cada asesor
    const resultado = Object.keys(conteoRegistrosPorAsesor).map(Broker_ID => ({
        name: registros.find(registro => registro.Broker_ID === Broker_ID).Nombre_de_Broker,
        short_name: registros.find(registro => registro.Broker_ID === Broker_ID).Nombre_de_Broker.split(' ')[0],
        citas: conteoRegistrosPorAsesor[Broker_ID],
        Broker_ID: Broker_ID
    }));

    return resultado;
}