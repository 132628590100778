import React from 'react';

import "./RegistrosTable.scss"

const RegistrosTable = ({ data, small=false }) => {
  return (
    <div className={`registros-table-container ${small ? "small" : ""}`}>
      <table className="registros-table">
        <thead>
          <tr>
            <th>Broker ID</th>
            <th>Nombre de Broker</th>
            <th>Fecha</th>
            <th>Cliente</th>
            <th>Producto</th>
            <th>Vincular con (1)</th>
            <th>Porcentaje de Cierre</th>
          </tr>
        </thead>
        <tbody>
          {data.map((registro, index) => (
            <tr key={index}>
              <td>{registro.Broker_ID}</td>
              <td>{registro.Nombre_de_Broker}</td>
              <td>{registro.Fecha}</td>
              <td>{registro.Cliente}</td>
              <td>{registro.Producto}</td>
              <td>{registro["Vincular_con_(1)"]}</td>
              <td>{registro.Porcentaje_de_cierre}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RegistrosTable;
