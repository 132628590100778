import React from 'react';

import './SelectQuarter.scss'


// Array de opciones de trimestres
const generateQuarterOptions = () => {
  const options = [];
  const years = [2023, 2024, 2025];

  years.forEach(year => {
    for (let quarter = 1; quarter <= 4; quarter++) {
      options.push({ label: `${quarter}Q${year}`, value: `${quarter}Q${year}` });
    }
  });

  return options;
};

const quarterOptions = generateQuarterOptions();

// Componente SelectQuarter
const SelectQuarter = ({ quarter, setQuarter, setInitRender }) => {
  return (
    <select className='SelectQuarter' value={quarter} onChange={(e) => {setInitRender(4); setQuarter(e.target.value)}}>
      {quarterOptions.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectQuarter;
