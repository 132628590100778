export default function FiltrarRegistrosPorTrimestre(registrosDB, trimestre) {
  // Obtener el año y número de trimestre
  const trimestreParts = trimestre.split("Q");
  const trimestreNum = parseInt(trimestreParts[0]);
  const year = parseInt(trimestreParts[1]);

  // Definir la fecha de inicio y fin del trimestre
  const primerMesDelTrimestre = (trimestreNum - 1) * 3;
  const fechaInicioTrimestre = new Date(year, primerMesDelTrimestre, 1);
  const fechaFinTrimestre = new Date(year, primerMesDelTrimestre + 3, 0);

  // Filtrar los registros que caen dentro del rango del trimestre
  const registrosFiltrados = registrosDB.filter(registro => {
      const fechaRegistro = new Date(registro.Fecha.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
      return fechaRegistro >= fechaInicioTrimestre && fechaRegistro <= fechaFinTrimestre;
  });

  return registrosFiltrados;
}
