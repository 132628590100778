import React, { useState } from 'react';

import "./AsesoresTable.scss"

const AsesoresTable = ({ data, onAsesorClick, small=false }) => {
  const [selectedAsesor, setSelectedAsesor] = useState(null);

  const handleRowClick = (asesor) => {
    setSelectedAsesor(asesor);
    onAsesorClick(asesor);
  };

  return (
    <div className={`asesores-table-container ${small ? "small" : ""}`}>
      <table className="asesores-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Celular</th>
            <th>Municipalidad</th>
          </tr>
        </thead>
        <tbody>
          {data.map((asesor) => (
            <tr key={asesor.ID} onClick={() => handleRowClick(asesor)}>
              <td>{asesor.ID}</td>
              <td>{asesor.Nombre}</td>
              <td>{asesor.Email}</td>
              <td>{asesor.Celular}</td>
              <td>{asesor.Municipalidad}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AsesoresTable;