export default function OrdenarArrayPorAtributo(array, atributo, orden = 'ascending') {
  if (!array || !Array.isArray(array) || array.length === 0) {
      console.error('El dataset proporcionado no es válido.');
      return array;
  }

  if (!array.every(item => item.hasOwnProperty(atributo))) {
      console.error('El atributo especificado no existe en todos los objetos del array.');
      return array;
  }

  if (orden === 'ascending') {
      return array.sort((a, b) => a[atributo] - b[atributo]);
  } else if (orden === 'descending') {
      return array.sort((a, b) => b[atributo] - a[atributo]);
  } else {
      console.error('Tipo de orden no válido. Selecciona "ascending" o "descending".');
      return array;
  }
}