export default function ObtenerSemanasDelTrimestre(trimestreAño) {
  const partes = trimestreAño.split("Q");
  const trimestre = parseInt(partes[0]);
  const año = parseInt(partes[1]);

  const mesesPorTrimestre = [
      [1, 2, 3], // Enero, Febrero, Marzo
      [4, 5, 6], // Abril, Mayo, Junio
      [7, 8, 9], // Julio, Agosto, Septiembre
      [10, 11, 12] // Octubre, Noviembre, Diciembre
  ];

  const semanas = [];
  const meses = mesesPorTrimestre[trimestre - 1];

  meses.forEach(mes => {
      const diasEnMes = new Date(año, mes, 0).getDate();
      let dia = 1;

      while (dia <= diasEnMes) {
          const semana = [];
          for (let i = 0; i < 7; i++) {
              if (dia > diasEnMes) break;
              const mesAbreviado = obtenerNombreMesAbreviado(mes);
              semana.push(`${mesAbreviado}${Math.ceil(dia / 7)}`);
              dia += 7;
          }
          semanas.push(semana);
      }
  });

  return semanas.flat(); // Devuelve un solo array
}

function obtenerNombreMesAbreviado(numeroMes) {
  const mesesAbreviados = [
      "ENE", "FEB", "MAR", "ABR", "MAY", "JUN",
      "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"
  ];
  return mesesAbreviados[numeroMes - 1];
}
