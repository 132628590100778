export default function ContarPorcentajeDeCierres(registros) {
  // Definir un objeto para almacenar el conteo de porcentajes
  const conteoPorcentajeDeCierres = {};

  // Recorrer cada registro
  registros.forEach(registro => {
      // Obtener el nombre del porcentaje del registro
      const porcentaje_de_cierre = registro.Porcentaje_de_cierre;

      // Si el porcentaje_de_cierre ya está en el conteo, aumentar su conteo en 1
      // Si no, inicializar su conteo en 1
      if (conteoPorcentajeDeCierres[porcentaje_de_cierre]) {
          conteoPorcentajeDeCierres[porcentaje_de_cierre]++;
      } else {
          conteoPorcentajeDeCierres[porcentaje_de_cierre] = 1;
      }
  });

  // Convertir el objeto de conteo a un array de objetos con el formato requerido
  const resultado = Object.keys(conteoPorcentajeDeCierres).map((porcentaje_de_cierre, index) => ({
      id: index,
      value: conteoPorcentajeDeCierres[porcentaje_de_cierre],
      label: porcentaje_de_cierre
  }));

  return resultado;
}
